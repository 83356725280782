@use "../../styles/partials/variables" as *;
@import "../../styles/partials/mixins";

.netasset-page {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  // border: cadetblue solid 2px;
  height: 42vw;
  @include mobile {
    height: 42vw;
  }
  @include mobile-b {
    height: 41.8vw;
  }
  @include mobile-c {
    height: 41.6vw;
  }
  @include tablet {
    height: 41.6vw;
  }
  @include nft-mod {
    height: 41.6vw;
  }
  @include nft-mod-b {
    height: 41.4vw;
  }
  @include nft-mod-c {
    height: 41.2;
  }
  @include nft-mod-d {
    height: 40.4vw;
  }
  @include desktop {
    height: 40.3vw;
  }
  @include desktop-b {
    height: 40.3vw;
  }
  @include desktop-c {
    height: 40.2vw;
  }
  @include desktop-d {
    height: 40.15vw;
  }
  @include desktop-e {
    height: 40.145vw;
  }

  &__body {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2vw;
    width: 100vw;
    text-align: center;
    margin-bottom: 25vw;
    color: rgb(203, 203, 203);
    // border: yellow solid 2px;
  }

  // &__built {
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: center;
  //   align-items: center;
  //   flex-wrap: wrap;
  //   width: 100vw;
  //   // border: blue solid 2px;
  // }

  // &__companies {
  //   width: 14vw;
  //   margin: 1vw 2vw;
  //   box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  // }
}
