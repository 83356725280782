//Responsive Media Queries {

@mixin mobile {
  @media (min-width: $mobile-breakpoint) {
    @content;
  }
}
@mixin mobile-b {
  @media (min-width: $mobile-breakpoint-b) {
    @content;
  }
}
@mixin mobile-c {
  @media (min-width: $mobile-breakpoint-c) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: $tablet-breakpoint) {
    @content;
  }
}
@mixin nft-mod {
  @media (min-width: $tablet-nft-module) {
    @content;
  }
}
@mixin nft-mod-b {
  @media (min-width: $tablet-nft-module-b) {
    @content;
  }
}
@mixin nft-mod-c {
  @media (min-width: $tablet-nft-module-c) {
    @content;
  }
}
@mixin nft-mod-d {
  @media (min-width: $tablet-nft-module-d) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: $desktop-breakpoint) {
    @content;
  }
}
@mixin desktop-b {
  @media (min-width: $desktop-breakpoint-b) {
    @content;
  }
}
@mixin desktop-b5 {
  @media (min-width: $desktop-breakpoint-b5) {
    @content;
  }
}
@mixin desktop-c {
  @media (min-width: $desktop-breakpoint-c) {
    @content;
  }
}
@mixin desktop-d {
  @media (min-width: $desktop-breakpoint-d) {
    @content;
  }
}
@mixin desktop-e {
  @media (min-width: $desktop-breakpoint-e) {
    @content;
  }
}
