.footer {
  display: flex;
  justify-content: center;
  margin-top: 10vw;
  margin-bottom: 2vw;
  height: 5vh;
}

.sig {
  display: flex;
  align-items: center;
  font-size: 1vw;
  color: rgba(255, 255, 255, 0.811);
}
