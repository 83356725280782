@use "./styles/partials/variables" as *;
@import "./styles/partials/mixins";

.main {
  text-align: center;
}

.buttons {
  margin-top: 10vw;
}

.first {
  width: 25vw;
  margin: 3vw;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

  @include nft-mod-d {
    width: 22vw;
    margin: 4vw;
  }
  @include desktop-b5 {
    width: 20vw;
    margin: 5vw;
  }
}

.mid {
  width: 25vw;
  margin: 3vw;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

  @include nft-mod-d {
    width: 22vw;
    margin: 4vw;
  }
  @include desktop-b5 {
    width: 20vw;
    margin: 5vw;
  }
}

.last {
  width: 25vw;
  margin: 3vw;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

  @include nft-mod-d {
    width: 22vw;
    margin: 4vw;
  }
  @include desktop-b5 {
    width: 20vw;
    margin: 5vw;
  }
}
