@use "../../styles/partials/variables" as *;
@import "../../styles/partials/mixins";

.created-page {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  // border: cadetblue solid 2px;
  height: 121.5vw;
  @include mobile {
    height: 121.5vw;
  }
  @include nft-mod-d {
    height: 70vw;
  }
  @include desktop-b5 {
    height: 54vw;
  }

  &__body {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2vw;
    width: 100vw;
    margin-top: 4vw;
    margin-bottom: 1vw;
    height: 4vw;
    color: rgb(203, 203, 203);
    // border: yellow solid 2px;

    @include nft-mod-d {
      margin-top: 4.9vw;
      margin-bottom: 4vw;
      height: 4vw;
    }
    @include desktop-b5 {
      margin-top: 3.5vw;
      margin-bottom: 4vw;
      height: 4vw;
    }
  }

  &__built {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 65vw;

    @include nft-mod-d {
      width: 60vw;
    }
    @include desktop-b5 {
      width: 100vw;
    }
    // border: blue solid 2px;
  }

  &__companies {
    width: 25vw;
    margin: 3vw 3vw;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

    @include nft-mod-d {
      width: 15.9vw;
      margin: 2vw 2vw;
    }
    @include desktop-b5 {
      width: 14vw;
      margin: 1.7vw 2vw;
    }
  }
}
